import React from "react";

function CtaFooter() {
    return(
        <section className="stay-touch-sec">
			<div className="container">
				<div className="txh-text text-center wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
					<h3>Stay Connected</h3>
					<h4>Madrid, ES</h4>
					<h2>info@crequs.com</h2>
					<span>(+34) 685-337-685</span>
				</div>
			</div>
		</section>
    )
}

export default CtaFooter