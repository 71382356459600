import React from 'react'
import Header from './header'
import Seo from './seo'

import { StaticQuery, graphql } from "gatsby"

import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';

import { IntlProvider } from 'react-intl';
import 'intl';

import "../styles/styles.css"
import CtaFooter from './cta-footer'
import Footer from './footer'

function Layout({children, transparent, light, robots, location, i18nMessages, pageId, metaTitle}) {

    return(<StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                languages {
                  defaultLangKey
                  langs
                }      
              }
            }
          }
        `}
        render={data => {
          const url = location.pathname;
          const { langs, defaultLangKey } = data.site.siteMetadata.languages;
          const langKey = getCurrentLangKey(langs, defaultLangKey, url);
          const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
          const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
          return (
          <IntlProvider
            locale={langKey}
            defaultLocale="es"
            messages={i18nMessages}
          >
            <Seo 
              robots={robots} 
              currentLang={langKey}
              pageId={pageId}
              langs={langsMenu}
              title={metaTitle} 
            />
              <Header 
                transparent={transparent}
                light={light}
                langs={langsMenu} 
                currentLang={langKey}
                pageId={pageId}
              />
              <main>{children}</main>
              <CtaFooter />
              <Footer 
                currentLang={langKey}
                pageId={pageId}
              />
          </IntlProvider>
        )
      }}
    />
    )
}

export default Layout