module.exports = {
    'home':{
        'es':'',
        'en':'en/'
    },
    'services':{
        'es':'servicios',
        'en':'en/services'
    },
    'portfolio':{
        'es':'portfolio',
        'en':'en/portfolio'
    },
    'portfolioSingle':{
        'es':'portfolio',
        'en':'en/portfolio'
    },
    'about':{
        'es':'nosotros',
        'en':'en/about-us'
    },
    'contact':{
        'es':'contacto',
        'en':'en/contact-us'
    },
    'legal':{
        'es':'aviso-legal',
        'en':'en/legal-policy'
    },
    'privacy':{
        'es':'politica-de-privacidad',
        'en':'en/privacy-policy'
    },
    'cookies':{
        'es':'politica-de-cookies',
        'en':'en/cookies-policy'
    },
    '404':{
        'es':'',
        'en':'en/'
    }
  };  