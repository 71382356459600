import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl";

import pages from '../data/urls';

function Header({transparent, light, langs, currentLang, pageId}){

	const isBrowser = typeof window !== "undefined"

	const [scroll, setScroll] = useState(false);
	const [openMenu, setopenMenu] = useState(false);
	useEffect(() => {
		if (isBrowser) {
			window.addEventListener("scroll", () => {
				setScroll(window.scrollY > 50);
			});
		}
	}, []);

	function MenuOpenHandler(){
		setopenMenu(true)
	}

	function MenuCloseHandler(){
		setopenMenu(false)
	}


	const links = langs.map(lang =>
		<Link to={"/"+pages[pageId][lang.langKey]} key={lang.langKey} style={{
			color: 'white'
		}}>
			<li className={lang.selected ? "active" : ''}>
			<img src={"/img/"+lang.langKey+".svg"} alt={lang.langKey} />
			</li>
		</Link>
	);

	const linksAuto = langs.map(lang =>
		<Link to={lang.link} key={lang.langKey} style={{
			color: 'white'
		}}>
			<li className={lang.selected ? "active" : ''}>
			<img src={"/img/"+lang.langKey+".svg"} alt={lang.langKey} />
			</li>
		</Link>
	);

	let actualPath = '';
	if(currentLang !== 'es'){
		actualPath = currentLang
	}

    return(
        <header className={transparent ? scroll ? "header-v3 transparent sticky animated slideInDown" : "header-v3 transparent" : scroll ? "header-v3 sticky animated slideInDown" : "header-v3"}>
			<div className="container">
				<div className={light ? "header-content header-content-v3 d-flex flex-wrap header-light" : "header-content header-content-v3 d-flex flex-wrap"}>
					<div className="logo">
						<Link to={"/"+actualPath}>
							<StaticImage 
								src="../images/logo-crequs.png"
								alt="Crequs"
								width={120}
								loading="eager"
								backgroundColor="transparent"
								placeholder="tracedSVG"
							/>
						</Link>
					</div>
					<div className="head-cat">
						<span><FormattedMessage id="webDevelopment" defaultMessage="Desarrollo web" />: <br />wordpress, drupal, react, &hellip;</span>
					</div>
					<ul className="hd-links">
						<li><FormattedMessage id="callUs" defaultMessage="Llámanos" />.  (+34) 685-337-685</li>
						<li>info@crequs.com</li>
					</ul>

					<ul className="language-switcher">
						{pageId == 'portfolioSingle' ? linksAuto :links}
					</ul>

					<button className="nav-toggle-btn a-nav-toggle" onClick={()=>MenuOpenHandler()}>
						<span className="nav-toggle nav-toggle-sm">
							<span className="stick stick-1"></span>
							<span className="stick stick-2"></span>
							<span className="stick stick-3"></span>
						</span>
					</button>
				</div>
			</div>
			<nav className={openMenu ? 'menuModal active animated slideInDown' : 'menuModal'}>
				<button  onClick={()=>MenuCloseHandler()}>
							<StaticImage 
								src="../images/close-white.svg"
								alt="Close"
								loading="eager"
								backgroundColor="transparent"
								placeholder="tracedSVG"
							/></button>
				<ul>
					<li><Link to={"/"+pages['home'][currentLang]} activeClassName="active"><FormattedMessage id="homePage" defaultMessage="Inicio" /></Link></li>
					<li><Link to={"/"+pages['services'][currentLang]} activeClassName="active"><FormattedMessage id="servicesPage" defaultMessage="Servicios" /></Link></li>
					<li><Link to={"/"+pages['portfolio'][currentLang]} activeClassName="active"><FormattedMessage id="portfolioPage" defaultMessage="Portfolio" /></Link></li>
					<li><Link to={"/"+pages['about'][currentLang]} activeClassName="active"><FormattedMessage id="aboutUsPage" defaultMessage="Sobre nosotros" /></Link></li>
					<li><Link to={"/"+pages['contact'][currentLang]} activeClassName="active"><FormattedMessage id="contactPage" defaultMessage="Contacto" /></Link></li>
					<li><a href="tel:+34685337685"><i className="lni lni-phone"></i> (+34) 685-337-685</a></li>
				</ul>
			</nav>
		</header>
        )

}

export default Header