import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import pages from '../data/urls';

const Seo = ({ description = '', meta = [], title, image, robots, pageId, langs, currentLang }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            description_en
          }
        }
      }
    `
  )

  console.log(currentLang)

  let hreflang = []
  langs.map(lang=>
    hreflang.push({
      rel: `alternate`,
      hrefLang:lang.langKey == 'en' ? 'en-US' : 'es-ES',
      href:site.siteMetadata.siteUrl+`/`+pages[pageId][lang.langKey],
    })  
  )

  let defaultMetaDescription = site.siteMetadata.description
  if(currentLang == 'en'){
    defaultMetaDescription = site.siteMetadata.description_en
  }
  if(currentLang == 'it'){
    defaultMetaDescription = site.siteMetadata.description_it
  }

  const metaDescription = description ? description : defaultMetaDescription
  return (
    <Helmet
      htmlAttributes={{
        currentLang,
      }}
      title={title ? title : site.siteMetadata?.title
      }
      link={hreflang}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: robots == `block` ? `noindex, nofollow` : `index, follow`,
        },
      ].concat(meta)}
    >     
    {/*<!-- El aviso de consentimiento de cookies de CookiePro comienza para crequs.com. -->*/}
    <script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="4cc42e2f-a8e0-4183-942b-0af3789c6cda" async/>
    <script type="text/javascript" dangerouslySetInnerHTML={{__html:'function OptanonWrapper() { }'}} async />
    {/*<!-- El aviso de consentimiento de cookies de CookiePro finaliza para crequs.com. -->*/}
</Helmet>
  )
}

export default Seo