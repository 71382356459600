import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FormattedMessage } from "react-intl";

import pages from '../data/urls';

function Footer({currentLang, pageId}){

	let actualPath = '';
	if(currentLang !== 'es'){
		actualPath = currentLang
	}

	const currentYear = new Date().getFullYear();

    return(
        <footer className="footer-v3">
			<div className="container">
				<div className="footer-content">
					<div className="ft-logo">
						
						<StaticImage
							src="../images/logo-crequs.png"
							alt="Crequs"
							width={120}
							loading="eager"
							backgroundColor="transparent"
							placeholder="tracedSVG"
						/>
					</div>
					<ul className="nav-btm-links">
						<li><Link to={"/"+pages['home'][currentLang]} activeClassName="active"><FormattedMessage id="homePage" defaultMessage="Inicio" /></Link></li>
						<li><Link to={"/"+pages['services'][currentLang]} activeClassName="active"><FormattedMessage id="servicesPage" defaultMessage="Servicios" /></Link></li>
						<li><Link to={"/"+pages['portfolio'][currentLang]} activeClassName="active"><FormattedMessage id="portfolioPage" defaultMessage="Portfolio" /></Link></li>
						<li><Link to={"/"+pages['about'][currentLang]} activeClassName="active"><FormattedMessage id="aboutUsPage" defaultMessage="Sobre nosotros" /></Link></li>
						<li><Link to={"/"+pages['contact'][currentLang]} activeClassName="active"><FormattedMessage id="contactPage" defaultMessage="Contacto" /></Link></li>
					</ul>
					<div className="cpy-text">
						<p>
							© {currentYear} Crequs.
							<FormattedMessage id="footerCopyrights" defaultMessage="Todos los derechos reservados"/> <br />
							<Link to={"/"+pages['legal'][currentLang]} rel="nofollow"><FormattedMessage id="legalPage" defaultMessage="Aviso legal"/></Link> - {` `}
							<Link to={"/"+pages['privacy'][currentLang]} rel="nofollow"><FormattedMessage id="privacyPage" defaultMessage="Política de privacidad"/></Link> - {` `} 
							<Link to={"/"+pages['cookies'][currentLang]} rel="nofollow"><FormattedMessage id="cookiesPage" defaultMessage="Política de cookies"/></Link></p>
					</div>
				</div>
			</div>
			<div className="btm-shape" style={{backgroundColor:'#dd2569', height:'10px'}}></div>
		</footer>
    )
}

export default Footer